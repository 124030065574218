<template>
    <div>
        <h1>{{ $t('pages.manager.salesmanOrganizationChart') }}</h1>       
        <div id="chart-container"></div>
    </div>
</template>

<script>       
    const apiUrl = process.env.VUE_APP_APIURL;

    export default {
        name: 'Orgchart',
        data() {
            return {               
            }
        },        
        mounted() {            
           //window.addEventListener('load', () => {               
                window.jQuery(function () {                    
                    const token = localStorage.getItem('access_token');
                   
                    window.jQuery.ajax({
                        type: "GET",
                        url: apiUrl + "/salesmanager/sales-orgchart",
                        dataType: "json",
                        headers: { Authorization: `Bearer ${token}`},
                        success: function (resp) {                            
                            window.jQuery('#chart-container').orgchart({                                
                                'data': resp,
                                'visibleLevel': 4,
                                'nodeContent': 'title',
                                'nodeID': 'id',
                                'createNode': function ($node, data) {
                                    var secondMenuIcon = window.jQuery('<i>', {
                                        'class': 'oci oci-info-circle second-menu-icon',
                                        click: function () {
                                            window.jQuery(this).siblings('.second-menu').toggle();
                                        }
                                    });
                                    var secondMenu = '<div class="second-menu"><img class="avatar" src="img/avatar/' + data.id + '.jpg"></div>';
                                    $node.append(secondMenuIcon).append(secondMenu);
                                }
                            });
                        }
                    })                 

                });

           // })
        }
    }

    
</script>

<style>

    /* chart styles */
    .orgchart {
        box-sizing: border-box;
        display: inline-block;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: linear-gradient(90deg, rgba(200, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0) 10%), linear-gradient(rgba(200, 0, 0, 0.15) 10%, rgba(0, 0, 0, 0) 10%);
        background-size: 10px 10px;
        border: 1px dashed rgba(0,0,0,0);
        padding: 20px 20px 0 20px;
    }

        .orgchart .hidden, .orgchart ~ .hidden {
            display: none !important;
        }

        .orgchart.b2t {
            transform: rotate(180deg);
        }

        .orgchart.l2r {
            position: absolute;
            transform: rotate(-90deg) rotateY(180deg);
            transform-origin: left top;
            text-align: center;
        }

        .orgchart.r2l {
            position: absolute;
            transform: rotate(90deg);
            transform-origin: left top;
            text-align: center;
        }

        .orgchart ~ .mask {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 999;
            text-align: center;
            background-color: rgba(0,0,0,0.3);
        }

            .orgchart ~ .mask .spinner {
                position: absolute;
                top: calc(50% - 50px);
                left: calc(50% - 50px);
            }

                .orgchart > .spinner::before,
                .orgchart ~ .mask .spinner::before {
                    width: 100px;
                    height: 100px;
                    border-width: 10px;
                    border-radius: 50px;
                    border-top-color: rgba(68, 157, 68, 0.8);
                    border-bottom-color: rgba(68, 157, 68, 0.8);
                    border-left-color: rgba(68, 157, 68, 0.8);
                }

        .orgchart .nodes {
            display: flex;
            list-style: none;
            padding-left: 0;
            margin: 0;
        }

        .orgchart .hierarchy {
            position: relative;
        }

            /* styles of link lines */
            .orgchart .hierarchy::before {
                content: "";
                position: absolute;
                top: -11px;
                left: 0;
                width: 100%;
                border-top: 2px solid rgba(217, 83, 79, 0.8);
                box-sizing: border-box;
            }

            .orgchart .hierarchy:first-child::before,
            .orgchart .hierarchy.isSiblingsCollapsed.left-sibs::before {
                left: calc(50% - 1px);
                width: calc(50% + 1px);
            }

            .orgchart .hierarchy:last-child::before,
            .orgchart .hierarchy.isSiblingsCollapsed.right-sibs::before {
                width: calc(50% + 1px);
            }

            .orgchart .hierarchy:not(.hidden):only-child::before {
                width: 2px;
            }

        .orgchart > .nodes > .hierarchy::before,
        .orgchart .isSiblingsCollapsed:not(.left-sibs):not(.right-sibs)::before,
        .orgchart .isSiblingsCollapsed.left-sibs.right-sibs::before,
        .orgchart .isSiblingsCollapsed.right-sibs:first-child:before,
        .orgchart .isSiblingsCollapsed.left-sibs:last-child:before,
        .orgchart .isCollapsedSibling::before,
        .orgchart .isCollapsedSibling .hierarchy::before,
        .orgchart .isChildrenCollapsed > .node:not(:only-child)::after,
        .orgchart .isCollapsedDescendant::before,
        .orgchart .isCollapsedDescendant > .node::before,
        .orgchart .isCollapsedDescendant > .node::after,
        .orgchart .isAncestorsCollapsed:only-child::before,
        .orgchart .isAncestorsCollapsed > .node::before {
            content: none;
        }

        /* excluding leaf node */
        .orgchart .node:not(:only-child)::after {
            content: "";
            position: absolute;
            bottom: -11px;
            left: calc(50% - 1px);
            width: 2px;
            height: 9px;
            background-color: rgba(217, 83, 79, 0.8);
        }

        .orgchart ul li .node.allowedDrop {
            border-color: rgba(68, 157, 68, 0.9);
        }

        .orgchart ul li .node.currentDropTarget {
            background-color: rgba(68, 157, 68, 0.9);
        }

        .orgchart ul li .node.selected {
            background-color: rgba(238, 217, 54, 0.5);
        }

        .orgchart ul li .node:hover {
            background-color: rgba(238, 217, 54, 0.5);
        }
        /* excluding root node */
        .orgchart > ul > li > ul li > .node::before {
            content: "";
            position: absolute;
            top: -11px;
            left: calc(50% - 1px);
            width: 2px;
            height: 9px;
            background-color: rgba(217, 83, 79, 0.8);
        }

        .orgchart > ul > li > ul li.isSiblingsCollapsed > .node::before {
            top: -13px;
            height: 11px;
        }

        /* node styling */
        .orgchart .node {
            box-sizing: border-box;
            display: inline-block;
            position: relative;
            margin: 0 0 20px 0;
            padding: 3px;
            border: 2px dashed transparent;
            text-align: center;
        }

        .orgchart.l2r .node, .orgchart.r2l .node {
            width: 50px;
            height: 140px;
        }

        .orgchart .node:hover {
            background-color: rgba(238, 217, 54, 0.5);
            transition: .5s;
            cursor: default;
            z-index: 20;
        }

        .orgchart .node.focused {
            background-color: rgba(238, 217, 54, 0.5);
        }

        .orgchart .ghost-node {
            position: fixed;
            left: -10000px;
            top: -10000px;
        }

            .orgchart .ghost-node rect {
                fill: #ffffff;
                stroke: #bf0000;
            }

        .orgchart .node.allowedDrop {
            border-color: rgba(68, 157, 68, 0.9);
        }

        .orgchart .node > .spinner {
            position: absolute;
            top: calc(50% - 1rem);
            left: calc(50% - 1rem);
        }

            .orgchart .node > .spinner::before {
                width: 2rem;
                height: 2rem;
                border-width: 0.2rem;
                border-radius: 1rem;
                border-top-color: rgba(68, 157, 68, 0.8);
                border-bottom-color: rgba(68, 157, 68, 0.8);
                border-left-color: rgba(68, 157, 68, 0.8);
            }

        .orgchart .node .title {
            box-sizing: border-box;
            padding: 2px;
            width: 130px;
            text-align: center;
            font-size: 0.75rem;
            font-weight: bold;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background-color: rgba(217, 83, 79, 0.8);
            color: #fff;
            border-radius: 4px 4px 0 0;
        }

        .orgchart.b2t .node .title {
            transform: rotate(-180deg);
            transform-origin: center bottom;
        }

        .orgchart.l2r .node .title {
            transform: rotate(-90deg) translate(-45px, -45px) rotateY(180deg);
            transform-origin: bottom center;
        }

        .orgchart.r2l .node .title {
            transform: rotate(-90deg) translate(-45px, -45px);
            transform-origin: bottom center;
        }

        .orgchart .node .title .symbol {
            float: left;
            margin-top: 12px;
            margin-left: 2px;
        }

            .orgchart .node .title .symbol::before {
                background-color: #fff;
                border-color: rgba(217, 83, 79, 0.8);
            }

            .orgchart .node .title .symbol::after {
                background-color: #fff;
            }

        .orgchart .node .content {
            box-sizing: border-box;
            padding: 2px;
            height: 20px;
            font-size: 0.625rem;
            border: 1px solid rgba(217, 83, 79, 0.8);
            border-radius: 0 0 4px 4px;
            text-align: center;
            background-color: #fff;
            color: #333;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .orgchart.b2t .node .content {
            transform: rotate(180deg);
            transform-origin: center top;
        }

        .orgchart.l2r .node .content {
            transform: rotate(-90deg) translate(-45px, -45px) rotateY(180deg);
            transform-origin: top center;
            width: 130px;
        }

        .orgchart.r2l .node .content {
            transform: rotate(-90deg) translate(-45px, -45px);
            transform-origin: top center;
            width: 130px;
        }

        .orgchart .node .edge {
            position: absolute;
            cursor: default;
            transition: .2s;
        }

            .orgchart .node .edge::before {
                border-color: rgba(68, 157, 68, 0.5);
            }

        .orgchart.noncollapsable .node .edge {
            display: none;
        }

        .orgchart .node .edge:hover {
            cursor: pointer;
        }

        .orgchart .edge:hover::before {
            border-color: #449d44;
        }

        .orgchart .node .verticalEdge {
            width: calc(100% - 6px);
            width: -webkit-calc(100% - 6px);
            width: -moz-calc(100% - 6px);
            height: 10px;
            left: 3px;
        }

            .orgchart .node .verticalEdge::before {
                position: absolute;
                left: calc(50% - 0.3125rem);
            }

        .orgchart .node .topEdge {
            top: -2px;
        }

            .orgchart .node .topEdge.oci-chevron-up::before {
                top: 2px;
            }

            .orgchart .node .topEdge.oci-chevron-down::before {
                bottom: 3px;
            }

        .orgchart .node .bottomEdge {
            bottom: -2px;
        }

            .orgchart .node .bottomEdge.oci-chevron-up::before {
                bottom: -3px;
            }

            .orgchart .node .bottomEdge.oci-chevron-down::before {
                bottom: 1px;
            }

        .orgchart .node .horizontalEdge {
            width: 10px;
            height: calc(100% - 6px);
            height: -webkit-calc(100% - 6px);
            height: -moz-calc(100% - 6px);
            top: 3px;
        }

        .orgchart .node .rightEdge {
            right: -2px;
        }

        .orgchart .node .leftEdge {
            left: -2px;
        }

        .orgchart .node .horizontalEdge::before {
            position: absolute;
            top: calc(50% - 0.3125rem);
        }

        .orgchart .node .rightEdge.oci-chevron-left::before {
            right: -3px;
        }

        .orgchart .node .rightEdge.oci-chevron-right::before {
            right: 1px;
        }

        .orgchart .node .leftEdge.oci-chevron-right::before {
            left: -3px;
        }

        .orgchart .node .leftEdge.oci-chevron-left::before {
            left: 1px;
        }

        .orgchart .node .toggleBtn {
            position: absolute;
            left: 5px;
            bottom: -2px;
        }

            .orgchart .node .toggleBtn::before {
                background-color: rgba(68, 157, 68, 0.6);
            }

            .orgchart .node .toggleBtn:hover::before {
                background-color: #449d44;
            }

    .oc-export-btn {
        margin-left: 0.5rem;
        padding: 0.5rem 1rem;
    }

    .orgchart .node {
        transition: transform 0.3s, opacity 0.3s;
    }

    .orgchart .slide-down {
        opacity: 0;
        transform: translateY(40px);
    }

    .orgchart.l2r .node.slide-down, .orgchart.r2l .node.slide-down {
        transform: translateY(130px);
    }

    .orgchart .slide-up {
        opacity: 0;
        transform: translateY(-40px);
    }

    .orgchart.l2r .node.slide-up, .orgchart.r2l .node.slide-up {
        transform: translateY(-130px);
    }

    .orgchart .slide-right {
        opacity: 0;
        transform: translateX(130px);
    }

    .orgchart.l2r .node.slide-right, .orgchart.r2l .node.slide-right {
        transform: translateX(40px);
    }

    .orgchart .slide-left {
        opacity: 0;
        transform: translateX(-130px);
    }

    .orgchart.l2r .node.slide-left, .orgchart.r2l .node.slide-left {
        transform: translateX(-40px);
    }

    /* styles for vertical nodes */
    .orgchart .hierarchy.hybrid > .node {
        margin-bottom: 0;
    }

        .orgchart .hierarchy.hybrid > .node::after {
            content: none;
        }

    .orgchart .nodes.vertical {
        display: block;
        margin: 0;
        padding-left: 18px;
    }

        .orgchart .nodes.vertical .nodes {
            list-style: none;
            display: block;
            margin: 0;
            padding-left: 18px;
            text-align: left;
        }

        .orgchart .nodes.vertical .node {
            margin-bottom: 0;
        }

            .orgchart .nodes.vertical .node::before,
            .orgchart .nodes.vertical .node::after {
                content: none;
            }

        .orgchart .nodes.vertical::before {
            content: '';
            display: inline-block;
            position: relative;
            height: 14px;
            width: 2px;
            left: -9px;
            background-color: rgba(217, 83, 79, 0.8);
        }

        .orgchart .nodes.vertical > .hierarchy:first-child::before {
            box-sizing: border-box;
            top: -4px;
            height: 30px;
            width: calc(50% - 2px);
            border-width: 2px 0 0 2px;
        }

        .orgchart .nodes.vertical .hierarchy {
            position: relative;
            text-align: left;
        }

            .orgchart .nodes.vertical .hierarchy::before,
            .orgchart .nodes.vertical .hierarchy::after {
                box-sizing: border-box;
                content: '';
                position: absolute;
                left: -6px;
                border-color: rgba(217, 83, 79, 0.8);
                border-style: solid;
                border-width: 0 0 2px 2px;
            }

            .orgchart .nodes.vertical .hierarchy::before {
                top: -4px;
                height: 30px;
                width: 11px;
            }

            .orgchart .nodes.vertical .hierarchy::after {
                top: 1px;
                height: 100%;
            }

            .orgchart .nodes.vertical .hierarchy:first-child::after {
                box-sizing: border-box;
                top: 24px;
                width: 11px;
                border-width: 2px 0 0 2px;
            }

            .orgchart .nodes.vertical .hierarchy:last-child::after {
                box-sizing: border-box;
                border-width: 2px 0 0;
            }

    /* custom icons for orgchart */
    .oci {
        display: inline-block;
        position: relative;
        font-style: normal;
        font-family: Arial;
    }

    .oci-chevron-up::before {
        content: "";
        display: inline-block;
        box-sizing: border-box;
        vertical-align: text-bottom;
        width: 0.625rem;
        height: 0.625rem;
        border-width: 0 0.2rem 0.2rem 0;
        border-style: solid;
        border-color: #000;
        background: transparent;
        transform: rotate(-135deg);
    }

    .oci-chevron-right::before {
        content: "";
        display: inline-block;
        box-sizing: border-box;
        width: 0.625rem;
        height: 0.625rem;
        border-width: 0 0.2rem 0.2rem 0;
        border-style: solid;
        border-color: #000;
        background: transparent;
        transform: rotate(-45deg);
    }

    .oci-chevron-down::before {
        content: "";
        display: inline-block;
        box-sizing: border-box;
        width: 0.625rem;
        height: 0.625rem;
        border-width: 0 0.2rem 0.2rem 0;
        border-style: solid;
        border-color: #000;
        background: transparent;
        transform: rotate(45deg);
    }

    .oci-chevron-left::before {
        content: "";
        display: inline-block;
        box-sizing: border-box;
        width: 0.625rem;
        height: 0.625rem;
        border-width: 0 0.2rem 0.2rem 0;
        border-style: solid;
        border-color: #000;
        background: transparent;
        transform: rotate(135deg);
    }

    .oci-leader::before {
        position: absolute;
        content: "";
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 0.2rem;
        background: #000;
        top: -0.75rem;
        left: 0.1rem;
    }

    .oci-leader::after {
        position: absolute;
        content: "";
        display: inline-block;
        width: 0.875rem;
        height: 0.375rem;
        border-radius: 0.25rem 0.25rem 0 0;
        background: #000;
        top: -0.3rem;
        left: -0.125rem;
    }

    .oci-plus-square::before {
        content: "+";
        display: inline-block;
        vertical-align: text-bottom;
        text-align: center;
        width: 1rem;
        height: 1rem;
        background-color: #000;
        color: #fff;
    }

    .oci-plus-circle::before {
        content: "+";
        display: inline-block;
        vertical-align: text-bottom;
        text-align: center;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        background-color: #000;
        color: #fff;
    }

    .oci-minus-square::before {
        content: "−";
        display: inline-block;
        vertical-align: text-bottom;
        text-align: center;
        width: 1rem;
        height: 1rem;
        background-color: #000;
        color: #fff;
    }

    .oci-minus-circle::before {
        content: "−";
        display: inline-block;
        vertical-align: text-bottom;
        text-align: center;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        background-color: #000;
        color: #fff;
    }

    .oci-arrow-circle-up::before {
        content: "▲";
        display: inline-block;
        text-align: center;
        vertical-align: text-bottom;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        background-color: #000;
        color: #fff;
        font-size: 0.875rem;
    }

    .oci-arrow-circle-down::before {
        content: "▼";
        text-align: center;
        display: inline-block;
        vertical-align: text-bottom;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        background-color: #000;
        color: #fff;
        font-size: 0.875rem;
    }

    .oci-info-circle::before {
        content: "i";
        display: inline-block;
        vertical-align: text-bottom;
        width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        background-color: #000;
        color: #fff;
        text-align: center;
        font-weight: bold;
    }

    .oci-spinner::before {
        content: "";
        vertical-align: text-bottom;
        display: inline-block;
        box-sizing: border-box;
        width: 1rem;
        height: 1rem;
        border: 0.1rem solid #000;
        border-right-color: transparent;
        border-radius: 0.625rem;
        animation: oci-infinite-spinning .75s linear infinite;
    }

    @keyframes oci-infinite-spinning {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    html, body {
        height: 100%;
        margin: 0;
        padding: 0;
        padding: 1px;
        box-sizing: border-box;
    }

    body {
        font-family: Arial;
        color: #333333;
    }

    #chart-container {
        position: relative;
        height: 420px;
        border: 1px solid #aaa;
        margin: 0.5rem;
        overflow: auto;
        text-align: center;
    }




    .orgchart .second-menu-icon {
        transition: opacity .5s;
        opacity: 0;
        right: -5px;
        top: -5px;
        z-index: 2;
        position: absolute;
    }

        .orgchart .second-menu-icon::before {
            background-color: rgba(68, 157, 68, 0.5);
        }

        .orgchart .second-menu-icon:hover::before {
            background-color: #449d44;
        }

    .orgchart .node:hover .second-menu-icon {
        opacity: 1;
    }

    .orgchart .node .second-menu {
        display: none;
        position: absolute;
        top: 0;
        right: -70px;
        border-radius: 35px;
        box-shadow: 0 0 10px 1px #999;
        background-color: #fff;
        z-index: 1;
    }

        .orgchart .node .second-menu .avatar {
            width: 60px;
            height: 60px;
            border-radius: 30px;
            float: left;
            margin: 5px;
        }
</style>

